import {useEffect, useState} from "react";

import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Backup from "@mui/icons-material/Backup";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { v4 as uuidv4 } from 'uuid';
import moment from "moment";

import { db } from "../db";

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

export default function HomePage() {
  const [wireless, setWireless] = useState([]);
  const [column, setColumn] = useState(0);
  const [row, setRow] = useState(0);
  const [deleteId, setDeleteId] = useState('');

  const [openDeleteAll, setOpenDeleteAll] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const handleClickOpenDeleteAll = () => {
    setOpenDeleteAll(true);
  };

  const handleCloseDeleteAll = () => {
    setOpenDeleteAll(false);
  };

  const handleClickOpenDelete = (deleteId) => {
    setDeleteId(deleteId);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  async function getData(){
    /*await fetch("http://192.168.42.1:3977/api/dato/agregar", { method: "POST", headers: {}, body: JSON.stringify({"Ct":2})}).then((res) => {
        console.log(res);
      });*/

      let request = {
        'Ct': 2,
        'Pn': 1
      };
  
      fetch("http://192.168.42.1:3977/api/dato/agregar", { method: "POST", headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(request)}).then(res => res.json()).then((res) => {
        console.log(res);
      });

    /*let tmp_wireless = {
      id: uuidv4(),
      date: moment().format('DD-MM-YYYY HH:mm:ss'),
      data: '["xd":"aaa"]',
      view: 'T=30',
      number: column+'-'+row,
      wireless: 'A1:A1:A1'
    };

    await db.wireless.add(tmp_wireless);

    tmp_wireless = [...wireless, tmp_wireless].reverse();

    setWireless(tmp_wireless);

    setRow(row + 1);*/
  }

  useEffect(() => {
    console.log(wireless);
}, [wireless]);

async function deleteDataAll() {
  await db.wireless.clear();
  setWireless([]);
  setOpenDeleteAll(false);
}

async function deleteData() {
  await db.wireless.delete(deleteId);

  let tmp_wireless = await db.wireless.toArray();
  setWireless(tmp_wireless);

  setOpenDelete(false);
}

useEffect(() => {
  const getWireless = async () => {
    let tmp_wireless = await db.wireless.reverse().toArray();

    setWireless(tmp_wireless);
  }

  getWireless();

}, []);

  return (
    <>
      <AppBar position="sticky" style={{ backgroundColor: "#222222" }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <IconButton color="inherit">
              <Backup />
            </IconButton>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center", marginTop: "10px" }}>
            <img src={"/img/logo_2.png"} alt="logo" width="200" />
          </Grid>
          <Grid item xs={4} style={{ textAlign: "right" }}>
            <IconButton color="inherit" onClick={handleClickOpenDeleteAll}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      </AppBar>

      <Box
        sx={{
          paddingTop: "20px",
          padding: "20px 10px",
          backgroundColor: "#e7ebf0",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Button variant="contained" style={{ width: "100%" }} onClick={getData}>
              GET DATA
            </Button>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Col"
              variant="outlined"
              style={{ backgroundColor: "#fff" }}
              fullWidth
              value={column}
              size="small"
              onChange={e => setColumn(parseInt(e.target.value))}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Row"
              variant="outlined"
              style={{ backgroundColor: "#fff" }}
              fullWidth
              value={row}
              size="small"
              onChange={e => setRow(parseInt(e.target.value))}
            />
          </Grid>
        </Grid>

        <br />

        {wireless?.map(wl =>
        <Demo key={wl.id} style={{ marginBottom: '10px' }}>
          <List>
            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="delete" onClick={() => handleClickOpenDelete(wl.id)}>
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemText
                primary={wl.number+' | '+wl.id}
                secondary={wl.date}
              />
            </ListItem>
          </List>
        </Demo>
        )}
      </Box>

      <Dialog
        open={openDeleteAll}
        onClose={handleCloseDeleteAll}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Eliminar todo
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Esta seguro que quiere eliminar todo?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleCloseDeleteAll}>No</Button>
          <Button variant="contained" onClick={deleteDataAll} autoFocus>
            Si
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Eliminar
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Esta seguro que quiere eliminar { deleteId }?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleCloseDelete}>No</Button>
          <Button variant="contained" onClick={deleteData} autoFocus>
            Si
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
